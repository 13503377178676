<template>
    <div class="popup-background" v-if="isopen" >
        <div class="popup-container" :style="containerstyle" v-click-outside="autoClose">
            <div class="header-container">
                <label class="header">{{ this.title }}</label>
                <v-icon name="times" scale="1" class="close-button" @click="close(false)"/>
            </div>

            <div class="popup-content">
                <slot></slot>
            </div>

            <div class="popup-button-container" v-if="!buttonless">
                <button :disabled="isloading" @click="close(true)" :class="submitbuttonclass">
                    {{ submitlabel }}
                </button>
                <button :disabled="isloading" @click="close(false)" :class="cancelbuttonclass">
                    {{ cancellabel }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-awesome/icons/times'

export default {
    name: 'popup',
    props: {
        title: String,
        isopen: {
            type: Boolean,
            default: false
        },
        submitlabel: {
            type: String,
            default: 'Yes'
        },
        cancellabel: {
            type: String,
            default: 'No'
        },
        isloading: {
            type: Boolean,
            default: false
        },
        submitbuttonclass: {
            type: String,
            default: ''
        },
        cancelbuttonclass: {
            type: String,
            default: ''
        },
        containerstyle: String,
        buttonless: Boolean,
        autoclose: {
            type: Boolean,
            default: true
        }
    },
    mounted() {
        document.body.classList.toggle('noscroll', true)
    },
    methods: {
        close: function(success) {
            // prevent closing the dialog while something is loading
            if (!this.isloading) {
                document.body.classList.toggle('noscroll', false)
                this.$emit('close', success)
            }
        },
        autoClose: function() {
            if (this.autoclose) this.close()
        }
    }
}
</script>

<style scoped>
.popup-background {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    padding: 10px;

    width: 100%;
    display: flex;
    flex-flow: column;
    overflow-y: auto;
    box-sizing: border-box;
}

.popup-container {
    background: #fff;
    position: relative;
    box-sizing: border-box;
    height: fit-content;
    width: 100%;
    max-width: 450px;
    padding: 20px;
    border-radius: 5px;
    margin: auto;
}

.header-container {
    font-weight: bold;
    font-size: 1.2em;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popup-content {
    margin-top: 20px;
    overflow-y: auto;
    box-sizing: border-box;
}

.popup-button-container {
    width: 100%;
    text-align: left;
    margin-top: 20px;
}

.close-button {
    box-sizing: border-box;
    height: 30px;
    width: 30px;
    padding: 5px;
    border-radius: 50%;
    color: #777;
}

.close-button:hover {
    background-color: #77777733;
    cursor: pointer;
}

@media(max-width: 450px) {
    .popup-container {
        max-width: 90%;
    }
}
</style>