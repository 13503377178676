<template>
    <div v-if="isloading" class="input-line-loading-placeholder shimmer big"></div>
    <div v-else class="input-wrapper" :class="[{ 'error' : error }, { 'with-label' : label }]">
        <div class="input-container" :id="inputid" @click="handleInput(!value)" :class="[{ 'active':this.value }, { 'display':this.display}]">
            <div class="toggle" 
                 :class="[{ 'with-label' : this.label }, { 'active' : this.value }]"
                 :style="backgroundcolor ? 'background-color: ' + backgroundcolor + ' !important;' : ''"
                 :disabled="disabled">
                <v-icon v-if="this.value" name="check"/>
                <v-icon v-else name="times" />
            </div>
            
            <label :for="inputid" class="checkbox-text" >{{ this.text }}</label>
        </div>
        
        <label :for="inputid" class="input-label" v-if="label" :style="backgroundcolor ? 'background-color: ' + backgroundcolor + ' !important;' : ''">{{ this.label }} {{ required ? '*' : '' }}</label>
        
        <div class="error-container">
            <label class="error-label" :class="{ 'show' : error }">{{ this.errortext }}</label>
        </div>
    </div>
</template>

<script>
import 'vue-awesome/icons/check'
import 'vue-awesome/icons/times'

export default {
    name: 'CheckboxInput',
    props: {
        value: {
            type: Boolean
        },
        text: String,
        display: Boolean,
        isloading: Boolean,
        disabled: Boolean,
        label: String,
        required: Boolean,
        error: Boolean,
        errortext: String,
        backgroundcolor: String,
        inputid: String
    },
    methods: {
        handleInput: function(value) {
            if (!this.display) this.$emit('input', value)
        },
        clicked: function(event) {
            this.$emit('click', event)
        }
    },
    computed: {
        computedid: function() {
            return this.inputid || `input-${this._rng(5)}`
        }
    }
}
</script>

<style scoped>
.input-wrapper {
    padding-top: 15px;
    position: relative;
}

.input-wrapper > .input-label {
    position: absolute;
    font-size: 1em;
    left: 10px;
    background: #fff;
    top: 6px;
    padding: 0px 5px;
    transition: .1s all;
    height: 10px;
    line-height: 10px;
}

.input-wrapper > .display {
    white-space: pre-wrap;
}

.input-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    font-size: 1.2em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-color: #e9e9e9;
    border: 1px solid #064A6C;
    border-radius: var(--input-border-radius);
    user-select: none;
    padding-right: 10px;
}

.input-container.active {
    background: #064A6C;
}

.input-container:hover:not(.display),
.input-container:hover:not(.display) > * {
    cursor: pointer;
}

.input-container.display {
    border: 1px solid #ccc;
    background: unset;
    
}

.input-container.display > .toggle:not(.active) {
    fill: #ccc;
}

.input-container.display > .toggle.active,
.input-container.display > .toggle.active + .checkbox-text {
    color:#064A6C;
    fill: #064A6C;
}

.input-container > .toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 30px;
    fill: #999;
}

.input-container > .toggle.active {
    fill: #fff;
}

.toggle.active + .checkbox-text {
    color: #fff;
}

.textinput-container > label {
    color: #fff;
}

.input-label {
    font-size: 1.1em;
    color: #2c2e2f;
}

.error-container {
    min-height: 15px;
    margin-top: 3px;
    padding-left: 10px;
    color: red;
    text-align: left;
}

.error-container > .error-label {
    visibility: hidden;
}

.error-container > .error-label.show {
    visibility: visible;
}

.input-wrapper.error >>> * {
    color: red;
    border-color: red;
}
</style>