import Vue from 'vue'

const mixin = {
    methods: {
        _leadingZero: function(digit) {
            return ('0' + digit).slice(-2)
        },
        _rng: function(length) {
            // generate a random string with a given length
            let result       = ''
            const characters = 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz0123456789'
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * characters.length))
            }
            return result
        },
        _formatDate(date) {
            return [
                this._leadingZero(date.getDate()),
                this._leadingZero(date.getMonth() + 1),
                date.getFullYear(),
            ].join('.');
        },
        _getContractType(numeric) {
            switch(numeric) {
                case 0:
                    return 'PostPaid'
                case 1:
                    return 'PostPaid'
                case 2:
                    return 'PrePaid (Bar)'
                case 3:
                    return 'PrePaid (Anonym)'
                case 4:
                    return 'PrePaid (Auto)'
                default:
                    return undefined;
            }
        },
        _currencyValueToFloat: function(value) {
            return (value/100).toFixed(2)
        },
        _getTransitionDuration(element) {
            // unset duration === 0s, so we dont need any null/unset checks
            let transitionduration = window.getComputedStyle(element).transitionDuration

            if (transitionduration.indexOf('s')) {
                transitionduration = transitionduration.substring(0, transitionduration.length -1)
                transitionduration = transitionduration * 1000
            } else if (transitionduration.indexOf('ms')) {
                transitionduration = transitionduration.substring(0, transitionduration.length -1)
            }

            return transitionduration
        }
    }
}

Vue.mixin(mixin)

export default mixin