<template>
    <div class="page-container">
        <div class="login-container">
            <NewCustomerLogin class="login" @login="login" @search="search"/>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'

import NewCustomerLogin from '@/components/systems/NewCustomerLogin.vue'

export default {
    name: 'login-page',
    data() {
        return {
            email: undefined,
            password: undefined
        }
    },
    methods: {
        login: function(event) {
            const url = Vue.getEnv('VUE_APP_MIDDLEWARE_URL') + '/userprofile/login'

            const body = {
                email: event.email,
                password: event.password
            }

            this.$root.$data.loading = true

            axios.post(url, body)
            .then(response => {
                // slow down the login to avoid flickering
                setTimeout(() => {
                    this.$root.$data.loading = false
                    this.$root.$data.auth = response.data
                    this.$router.push('/') // redirect to default page
                }, 500)
            })
            .catch(err => {
                // slow down negative case (5s)
                setTimeout(() => {
                    const errormessage = err.response?.data || err
                    Vue.$toast.error(errormessage)
                    this.$root.$data.loading = false
                }, 5000)
            })
        },
        search: function() {
            this.$root.$data.loading = true

            let url = Vue.getEnv('VUE_APP_MIDDLEWARE_URL') + '/session/url-anlageassistent'
            url += `?Mandant=${this.$root.$data.session.client}`
            url += `&KTNR=${this.$root.$data.session.corporation}`

            // avoid "unaturally" fast redirect
            setTimeout(() => {
                axios.get(url)
                .then(response => {
                    this.$root.$data.loading = false
                    window.location = response.data + '&cs=1'
                })
                .catch(err => {
                    this.$root.$data.loading = false
                    console.log(err)
                    Vue.$toast.error(err.message)
                })
            }, 500)
        }
    },
    components: {
        NewCustomerLogin
    }
}
</script>

<style scoped>
.login-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.login >>> input {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid;
}
</style>