import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginPage from '@/views/LoginPage.vue'
import UserDataPage from '@/views/UserDataPage.vue'
import EditUserDataPage from '@/views/EditUserDataPage.vue'

Vue.use(VueRouter)

const DEFAULT_ROUTE_NAME = 'user-data'

const routes = [
    {
        path: '/login',
        name: 'login',
        component: LoginPage,
    },
    {
        path: '/user-data',
        name: 'user-data',
        component: UserDataPage,
    },
    {
        path: '/user-data/edit',
        name: 'edit-user-data',
        component: EditUserDataPage
    },
    // ----- DEFAULT ROUTE MUST BE LAST! -----
    {
        path: '*',
        name: 'else',
        component: undefined,
        // always redirect to landing page
        beforeEnter: (to, from, next) => {
            next({ name: DEFAULT_ROUTE_NAME, query: to.query })
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    // only needed if not served in webserver root dir
    // base: process.env.BASE_URL,
    routes
})

// check if the session is still valid
router.beforeEach((to, from, next) => {
    // preserve query
    // we merge both querys in case the to.query is not emtpy
    // parameters present in both querys will be overwritten by the to.query values!
    const fromhasquery = !!Object.keys(from.query).length
    const tohasquery = !!Object.keys(to.query).length

    if ((fromhasquery && !tohasquery)) {
        Object.assign(to.query, { ...from.query, ...to.query}) // route.query is readonly, so we can't do this: to.query = { ...from.query, ...to.query}
    }

    const appdata = router.app.$options.data()
    const loggedin = appdata.auth !== undefined && appdata.auth.userid !== undefined && appdata.auth.token !== undefined

    if (!loggedin && to.name !== 'login') {
        next({ name: 'login', query: to.query})
    } else if ((fromhasquery && !tohasquery)) {
        next(to)
    } else {
        next()
    }
})

export default router