import Vue from 'vue'

const utils = {
    install(Vue /*, options*/) {
        Vue.getEnv = (name) => {
            return window?.configs?.[name] || process.env[name]
        },
        Vue.middleware = () => {
            return Vue.getEnv('VUE_APP_MIDDLEWARE_URL')
        }
    }
}

Vue.use(utils)

export default utils