<template>
    <div class="header-container">
        <img class="client-logo" :src="logouri"/>

        <div class="menu-wrapper">
            <div class="menu-content">
                <button class="passive logout" v-if="$root.$data.auth.token !== undefined" @click="logout">
                    <v-icon class="icon" name="user"/>
                    <label>Logout</label>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-awesome/icons/user'

export default {
    name: 'Header',
    computed: {
        logouri() {
            return this.$root.$data.systemconfiguration.clientlogo
        }
    },
    methods: {
        logout: function() {
            this.$root.$data.loading = true
            setTimeout(() => {
                this.$root.$data.logout()
                this.$root.$data.loading = false
            }, 500)
        }
    }
}
</script>

<style scoped>
.menu-wrapper {
    margin-right: 10px;
}

.header-container {
    width: 100%;
    height: 100%;
    text-align: left;
    display: flex;
    align-items: center;
}

.header-container > .client-logo {
    margin-left: 10px;
    height: 40px;
    width: 180px;
}

.logout {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
</style>