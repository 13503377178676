<template>
    <div class="component-container" v-if="!loading">
        <div class="textarea-container" :style="inputStyle" :class="{ 'with-label' : label }">
            <label class="input-label" :for="inputid" :class="{ 'with-content' : value && value.trim() !== '' }" :style="backgroundcolor ? 'background-color: ' + backgroundcolor + ' !important;' : ''" v-if="label">{{ this.label }} {{ required ? '*' : '' }}</label>

            <textarea :ref="taref" v-if="!toggleDisplay" @input="handleInput($event.target.value)" :value="value" :id="inputid" :required="required" :placeholder="placeholder" :disabled="disabled">
            </textarea>
            
            <label v-else>{{ value }}</label>
        </div>
    </div>
    <div v-else class="input-line-loading-placeholder shimmer big"></div>
</template>

<script>
export default {
    name: 'FloatingTextarea',
    props: {
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        labelStyle: {
            type: String,
            default: ''
        },
        inputStyle: {
            type: String,
            default: ''
        },
        toggleDisplay: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        taref : {
            type: String,
            default: ''
        },
        label: String,
        value: String,
        placeholder: String,
        inputid: String,
        backgroundcolor: String
    },
    methods: {
        handleInput: function(value) {
            this.$emit('input', value)
        }
    }
}
</script>

<style scoped>
.component-container {
    width: 100%;
    text-align: left;
}

.label-container {
    position: absolute;
    text-align: left;
    font-size: 0.8em;
    left: 10px;
    top: -7.5px;
    padding: 0 5px;
    background: var(--color-fg-default);
    border-radius: 10px;
}

.textarea-container {
    width: 100%;
    position: relative;
}

.textarea-container.with-label {
    padding-top: 15px;
}

textarea {
    width: 100%;
    resize: vertical;
    min-height: 100px;
    padding-top: 10px;
    padding-left: 10px;
    border-radius: 3px;
}

.input-line-loading-placeholder {
    height: 130px;
}

.textarea-container > .input-label {
    position: absolute;
    font-size: 1em;
    left: 10px;
    background: #fff;
    top: 6px;
    padding: 0px 5px;
    transition: .1s all;
    height: 10px;
    line-height: 10px;
    font-size: 1.1em;
    color: #2c2e2f;
}

.textarea-container > .input-label.with-content {
    top: 0px;
    left: 0px;
}
</style>