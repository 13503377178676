<template>
    <div class="round-container content-container">
        <div class="login-wrapper">
            <div class="image">
                <img src="@/assets/img/login-banner.jpg" :title="imgattr" />
            </div>
            <div class="login-content">
                <div class="search">
                    <!-- <h2>Trip search</h2> -->
                    <div class="info">
                        <h2>Noch kein Kunde?</h2>
                        <p>Holen Sie sich hier Ihr erstes Abo und verwalten Sie dieses und weitere bequem online!</p>
                    </div>
                    <div class="input-wrapper">
<!--                         <TextInput v-model="from" placeholder="Von"/>
                        <TextInput v-model="to" placeholder="Nach"/> -->
                    </div>
                    <button class="passive submit" @click="search">Jetzt bestellen</button>
                </div>
                <div class="login">
                    <h2>Bereits Kunde?</h2>
                    <p>Hier geht es zu Ihrem persönlichen Bereich</p>
                    <div class="input-wrapper">
                        <TextInput v-model="email" placeholder="E-Mail" :onenter="login"/>
                        <TextInput v-model="password" placeholder="Passwort" :type="'password'" :onenter="login"/>
                    </div>
                    <button class="active submit" @click="login">Anmelden</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TextInput from '@/components/systems/elements/TextInput.vue'

const EMIT_LOGIN = 'login'
const EMIT_SEARCH = 'search'

export default {
    name: 'new-customer-login',
    emits: [EMIT_LOGIN, EMIT_SEARCH],
    data() {
        return {
            imgattr:  'Archive vector created by vectorjuice - www.freepik.com - https://www.freepik.com/author/vectorjuice',
            email:    null,
            password: null,
            from:     null,
            to:       null
        }
    },
    methods: {
        login: function() {
            this.$emit(EMIT_LOGIN, { email: this.email, password: this.password })
        },
        search: function() {
            this.$emit(EMIT_SEARCH, { from: this.from, to: this.to })
        }
    },
    components: {
        TextInput
    }
}
</script>

<style scoped>
.content-container {
    height: fit-content;
    border-radius: 8px;
    width: 412px;
    margin: 0 auto;
}

.login-wrapper {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 2px 5px rgb(100 100 150 / 30%);
}

.login-content {
    background: #fff;
    display: flex;
    align-items: center;
    gap: 1em;
    flex-direction: column;
    padding: 30px 10px;
    height: fit-content;
}

.image {
    width: 372px;
    height: 111px;
    overflow: hidden;
}

.image > img {
    width: 100%;
    height: 100%;
}

.login-content > div {
    flex: 1;
    min-width: 300px;
    padding: 0 30px;
    max-width: 350px
}

.login-content > .login {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login-content > .search {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login-content:before {
    content: "";
    border: 1px solid #d3d3d3;
    align-self: stretch;
}

.login-content > div:nth-of-type(1) {
    order: -1;
}

.input-wrapper {
    display: flex;
    flex-direction: column;
}

.submit {
    width: 100%;
    margin: 0 auto;
}

.login > p,
.info > p {
    font-size: 1.2em;
}

@media(min-width: 576px) {

}

@media(min-width: 768px) {
    .content-container {
        width: 700px;
    }
    
    .login-content {
        flex-direction: row;
    }

    .login-content > div {
        min-height: 250px;
    }

    .image {
        height: 198px;
        width: 100%;
    }
}

@media(min-width: 992px) {
    .image {
        height: 198px;
    }
}
</style>