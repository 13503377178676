import axios, { AxiosError } from 'axios'

const towssession = {
    // options: { session: SESSION, config: SYSTEMCONFIGURATION }
    install(Vue, options) {
        let SESSION = options.session
        let SYSTEMCONFIGURATION = options.config

        // configure axios
        axios.defaults.timeout = 60000 // default timeout
        const HEADER_HQ_TOKEN = 'hq_token'
        axios.interceptors.request.use(config => {
            // set sessionid as header in every request
            config.headers[HEADER_HQ_TOKEN] = SESSION.id
            return config
        })

        // function calls iteself again after given timeout
        const getSession = async (timeout, session, config) => {
            session.refreshing = true

            let client = (new URL(window.location)).searchParams.get('client')
            let corporation = (new URL(window.location)).searchParams.get('corporation')

            if (!client) {
                console.log('no-fronted-client')
            }

            if (!corporation) {
                console.log('no-fronted-corporation')
            }

            return axios.get(Vue.getEnv('VUE_APP_MIDDLEWARE_URL') + '/session' + `?client=${client}` + `&corporation=${corporation}`)
            .then(response => {
                const data = response.data
                const status = data.status
                const sessiondata = data.data

                if (!sessiondata || status.ErrorCode !== 0) {
                    throw status.ErrorMsg
                }

                session.id            = sessiondata.sessionid
                session.client        = sessiondata.client
                session.clientid      = sessiondata.clientid
                session.corporation   = sessiondata.corporation
                session.corporationid = sessiondata.corporationid
                session.refreshed     = new Date()
                session.refreshing    = false

                // start the refresh cycle
                setTimeout(getSession, timeout, timeout, session, config)

                // return session result once
                return { session, config }
            })
        }

        // get a new session every 10 minutes (600000ms)
        const timeout = 600000
        getSession(timeout, SESSION, SYSTEMCONFIGURATION)
        .then(result => {
            let session = result.session
            let config = result.config

            return axios.get(Vue.getEnv('VUE_APP_MIDDLEWARE_URL') + `/clients/${session.clientid}/systemconfiguration`)
            .then(response => {
                const body = response.data

                if (body.status.ErrorCode !== 0) {
                    throw body.statuts.ErrorMsg
                }

                config.configuration   = body.data.configuration
                config.linklist        = body.data.linklist

                session.available = true
                session.created = new Date()

                return { session, config }
            })
        })
        .then(result => {
            let session = result.session
            let config = result.config

            return axios.get(Vue.getEnv('VUE_APP_MIDDLEWARE_URL') + `/clients/${session.clientid}/corporations/${session.corporationid}`)
            .then(response => {

                const body = response.data

                if (body.status.ErrorCode !== 0) {
                    throw body.statuts.ErrorMsg
                }

                config.fields = JSON.parse(body.data.fieldconfig)

                return { session, config }
            })
        })
        .then(result => {
            let session = result.session
            let config = result.config

            return axios.get(Vue.getEnv('VUE_APP_MIDDLEWARE_URL') + `/clients/${session.clientid}/logo`)
            .then(response => {
                const body = response.data

                if (body.status.ErrorCode !== 0) {
                    throw body.statuts.ErrorMsg
                }

                config.clientlogo = body.data.logo

                return { session, config }
            })
        })
        .catch(err => {
            if (err && err instanceof AxiosError && err.response.data === 'no-client') {
                throw err.response.data
            } else {
                console.log(err)
                // app will stop refreshing in this case
                // --> try to reload the app after 2.5s
                setTimeout(() => {
                    window.location.reload()
                }, 2500)
            }
        })
    }
}

export default towssession