<template>
    <div id="app">
        <Header id="header" v-if="this.sessionavailable"/>

        <!--
            only render the router view if a session is available,
            this way, we dont need to check for that in every single page
        -->
        <router-view id="router-view" v-if="this.sessionavailable"/>

        <Footer id="footer" v-if="this.sessionavailable" />

        <Transition name="fade">
            <div class="loading" v-show="this.isloading">
                <div class="spinner"></div>
                <div class="label">Lade Daten...</div>
            </div>
        </Transition>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'App',
    data() {
        return {}
    },
    computed: {
        isloading() {
            const nosession = !this.sessionavailable
            const generalloading = this.$root.$data.loading

            return nosession || generalloading
        },
        sessionavailable() {
            return this.$root.$data.session.available
        },
        session() {
            return this.$root.$data.session
        }
    },
    components: {
        Header,
        Footer
    }
}
</script>

<style>
@import './assets/css/application.css';
@import './assets/css/toasts.css';
</style>

<style scoped>
.loading {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: #000000cc;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 999;
}

.loading > .spinner {
    width: 50px;
    height: 50px;
    border: 4px solid #fff;
    border-bottom-color: #ffffff33;
    border-radius: 50%;

    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.loading > .label {
    color: #fff;
    font-size: 1.4em;
    font-weight: bold;
    margin-top: 10px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
