<template>
    <div class="page-container">
        <div class="content-container">
            <div class="left">

                <div class="heading">
                    <label>Persönliche Daten und Verträge</label>
                </div>

                <Tile class="item personal-data" title="Persönliche Daten" @iconclick="goToUserEdit" v-if="cardholder && cardholder.Personendaten">
                    <div class="personal-data-details">
                        <div>
                            <label>Anrede</label>
                            <label>{{ cardholder.Personendaten.Anrede || '-' }}</label>

                            <label>Vorname</label>
                            <label>{{ cardholder.Personendaten.Vorname }}</label>

                            <label>Nachname</label>
                            <label>{{ cardholder.Personendaten.Nachname }}</label>

                            <label>Geburtstag</label>
                            <label>{{ _formatDate(new Date(cardholder.Personendaten.GebDatumDate)) }}</label>
                        </div>
                        <div>
                            <label>Straße</label>
                            <label>{{ cardholder.Personendaten.Strasse + ' ' + cardholder.Personendaten.HausNr }}</label>

                            <label>Ort</label>
                            <label>{{ cardholder.Personendaten.PLZ + ' ' + cardholder.Personendaten.Ort }}</label>

                            <label>Telefon</label>
                            <label>{{ cardholder.Personendaten.Telefon || '-' }}</label>

                            <label>Email</label>
                            <label>{{ cardholder.Personendaten.Email }}</label>
                        </div>
                    </div>

                    <template v-slot:icon>
                        <v-icon name="pen" />
                    </template>
                </Tile>

                <Tile v-if="width < 764" class="item new-contract" @iconclick="newcontract" :imgtype="1" title="Neuer Vertrag">
                    <label class="info">
                        Hier können Sie ein neues Abo beantragen.
                    </label>
                </Tile>

                <Tile :withouticon="true" class="item contract" v-for="contract in leftlist" :key="contract.IDBerechtigung" :imgtype="2" :title="`Vertrag: ${contract.IDBerechtigung}`" @iconclick="opencontract(contract)">

                    <div class="contract-info">
                        <h3>{{ contract.KA_prodBez }}</h3>
                        <div></div>

                        <label>Berechtigungsnummer</label>
                        <label>{{ contract.KA_AppInstanzNummer }}</label>

                        <label>Gültig ab</label>
                        <label>{{ _formatDate(new Date(contract.GueltigAb)) }}</label>

                        <label>Abrechnungsart</label>
                        <label>{{ _getContractType(parseInt(contract.VertragsArtAusFKI, 10)) }}</label>
                    </div>

                    <template v-slot:buttons>
                        <button class="passive" @click="terminate(contract)">Kündigung</button>
                        <button class="passive" @click="replace(contract)" v-if="canbereplaced">Ersatzkarte</button>
                    </template>

                    <template v-slot:icon>
                        <v-icon name="info" />
                    </template>
                </Tile>
            </div>

            <div class="right" v-if="contracts">
                <Tile v-if="width > 763" class="item new-contract" @iconclick="newcontract" :imgtype="1" title="Neuer Vertrag">
                    <label class="info">
                        Hier können Sie ein neues Abo beantragen.
                    </label>
                </Tile>

                <Tile :withouticon="true" class="item contract" v-for="contract in rightlist" :key="contract.IDBerechtigung" :imgtype="2" :title="`Vertrag: ${contract.IDBerechtigung}`" @iconclick="opencontract(contract)">

                    <div class="contract-info">
                        <h3>{{ contract.KA_prodBez }}</h3>
                        <div></div>

                        <label>Berechtigungsnummer</label>
                        <label>{{ contract.KA_AppInstanzNummer }}</label>

                        <label>Gültig ab</label>
                        <label>{{ _formatDate(new Date(contract.GueltigAb)) }}</label>

                        <label>Abrechnungsart</label>
                        <label>{{ _getContractType(parseInt(contract.VertragsArtAusFKI, 10)) }}</label>
                    </div>

                    <template v-slot:buttons>
                        <button class="passive" @click="terminate(contract)">Kündigung</button>
                        <button class="passive" @click="replace(contract)" v-if="canbereplaced">Ersatzkarte</button>
                    </template>

                    <template v-slot:icon>
                        <v-icon name="info" />
                    </template>
                </Tile>
            </div>
        </div>

        <Popup
            id="replacement-popup"
            title="Berechtigung ersetzen"
            submitlabel="Abschicken"
            cancellabel="Abbrechen"
            :isopen="replacement.open"
            @close="closeReplacementPopup"
            submitbuttonclass="active"
            cancelbuttonclass="passive">
                <DropdownInput label="Grund" :error="$v.replacement.$error" :defaultoption="true" :value="replacement.reason.displayid" @input="event => replacement.reason = event" :optionlist="replacement.reasons" :required="$v.replacement.reason.$params.required !== undefined" />
                <TextareaInput label="Ihre Nachricht an uns" v-model="replacement.message" />
        </Popup>

        <Popup
            id="termination-popup"
            title="Berechtigung kündigen"
            submitlabel="Abschicken"
            cancellabel="Abbrechen"
            :isopen="termination.open"
            @close="closeTerminationPopup"
            submitbuttonclass="active"
            cancelbuttonclass="passive"
            :autoclose="false">
                <DropdownInput label="Grund" :error="$v.termination.reason.$error" :defaultoption="true" :value="termination.reason.displayid" @input="event => termination.reason = event" :optionlist="termination.reasons" :required="$v.termination.reason.$params.required !== undefined" />
                <DateInput label="Kündigungsdatum" :error="$v.termination.date.$error" v-model="termination.date" :required="$v.termination.date.$params.required !== undefined"/>
                <TextareaInput label="Ihre Nachricht an uns" v-model="termination.message"/>
        </Popup>
    </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'

import 'vue-awesome/icons/pen'
import 'vue-awesome/icons/info'

import Tile from '../components/systems/elements/Tile.vue'
import Popup from '../components/systems/elements/Popup.vue'
import DateInput from '../components/systems/elements/DateInput.vue'
import TextareaInput from '../components/systems/elements/TextareaInput.vue'
import DropdownInput from '../components/systems/elements/DropdownInput.vue'

import {
    required
} from 'vuelidate/lib/validators'

export default {
    name: 'user-data-page',
    data() {
        return {
            width: document.documentElement.clientWidth,
            replacement: {
                reasons: [],
                reason: { displayid: -1, displayname: ''},
                message: '',
                open: false,
                contract: undefined
            },
            termination: {
                reasons: [],
                reason: { displayid: -1, displayname: ''},
                date: new Date(),
                message: '',
                contract: undefined,
                open: false,
            }
        }
    },
    validations() {
        let validations = {
            replacement: {
                reason: {
                    required,
                    notdefault: value => value.displayid !== -1
                }
            },
            termination: {
                date: {
                    required,
                    // only last day of month?
                },
                reason: {
                    required,
                    notdefault: value => value.displayid !== -1
                }
            }
        }

        return validations
    },
    mounted() {
        window.addEventListener('resize', this.onresize)
        this.init()
    },
    computed: {
        leftlist() {
            return this.contracts.filter((el, idx) => { return idx % 2 === 1})
        },
        rightlist() {
            return this.contracts.filter((el, idx) => { return idx % 2 === 0})
        },
        contracts() {
            return this.$root.$data.customer?.AktiveBerechtigungen?.TOWSBerechtigungErweitert || []
        },
        cardholder() {
            return this.$root.$data.customer?.FKI || {}
        },
    },
    methods: {
        init: function() {
            this.$root.$data.loading = true

            let url = Vue.middleware()
            url += '/customer/' + this.$root.$data.auth.token

            axios.get(url)
            .then(response => {
                const status = response.data.status
                const data   = response.data.data

                if (status.ErrorCode !== 0) {
                    Vue.$toast.error(status.ErrorMsg)
                    this.$root.$data.logout()
                } else {
                    this.$root.$data.customer = data
                }

                this.$root.$data.loading = false
            })
            .catch(err => {
                console.log(err)
                this.$root.$data.loading = false
            })
        },
        canbereplaced(contract) {
            return contract.ErsatzkartenModus?.VerfahrenErstzkarte === 1 || false
        },
        newcontract: function() {
            this.$root.$data.loading = true

            let url = Vue.getEnv('VUE_APP_MIDDLEWARE_URL') + '/session/url-anlageassistent'
            url += `?Mandant=${this.$root.$data.session.client}`
            url += `&KTNR=${this.$root.$data.session.corporation}`

            // avoid "unaturally" fast redirect
            setTimeout(() => {
                axios.get(url)
                .then(response => {
                    let anlageurl = response.data
                    anlageurl += `&token=${this.$root.$data.auth.token}`
                    anlageurl += '&cs=1'

                    window.location = anlageurl
                })
                .catch(err => {
                    this.$root.$data.loading = false
                    console.log(err)
                    Vue.$toast.error(err.message)
                })
            }, 500)
        },
        goToUserEdit: function() {
            this.$root.$data.loading = true
            setTimeout(() => {
                this.$router.push({ name: 'edit-user-data' })
                this.$root.$data.loading = false
            }, 500)
        },
        onresize: function() {
            this.width = document.documentElement.clientWidth
        },
        replace: function(contract) {
            this.$root.$data.loading = true

            let reasonsurl = Vue.middleware()
            reasonsurl += '/clients/' + this.$root.$data.session.clientid
            reasonsurl += '/replacement-reasons'

            axios.get(reasonsurl)
            .then(response => {
                this.replacement.reasons = response.data.data.reasons.map(entry => {
                    return {
                        displayid: entry.IDGrund,
                        displayname: entry.Bezeichnung + ' - ' + this._currencyValueToFloat(entry.GebuehrInCent) + '€',
                        original: entry
                    }
                })

                this.replacement.open = true
                this.replacement.contract = contract
                this.$root.$data.loading = false
            })
            .catch(err => {
                this.$root.$data.loading = false
                console.log(err)
                Vue.$toast.$error(err)
            })
        },
        terminate: function(contract) {
            this.$root.$data.loading = true

            let reasonsurl = Vue.middleware()
            reasonsurl += '/clients/' + this.$root.$data.session.clientid
            reasonsurl += '/termination-reasons'

            axios.get(reasonsurl)
            .then(response => {
                this.termination.reasons = response.data.data.reasons.map(entry => {
                    return {
                        displayid: entry.IDKuendigungsgrund,
                        displayname: entry.KundigungsgrundText,
                        original: entry
                    }
                })

                this.termination.open = true
                this.termination.contract = contract
                this.$root.$data.loading = false
            })
            .catch(err => {
                this.$root.$data.loading = false
                console.log(err)
                Vue.$toast.$error(err)
            })
        },
        opencontract: function(contract) {
            console.log(contract)
            // TODO: navigate
            // this.$router.push({ name: 'contract-details' })
        },
        closeTerminationPopup: function(submit) {
            if (submit) {
                this.$v.termination.$touch()

                if (this.$v.$anyError) {
                    return
                }

                this.$root.$data.loading = true

                let terminationurl = Vue.middleware()
                terminationurl += '/contracts/' + this.termination.contract.IDBerechtigung
                terminationurl += '/terminate'

                const terminationbody = {
                    date: this.termination.date,
                    reason: this.termination.reason.displayname,
                    message: this.termination.message
                }

                axios.post(terminationurl, terminationbody)
                .then(response => {

                    if (response.data.status.ErrorCode !== 0 ) {
                        throw response.data.status.ErrorMsg
                    } else {
                        Vue.$toast.info('Ihr Kündigungsantrag wurde erfolgreich übermittelt')
                    }

                    this.termination.contract = undefined
                    this.termination.open = false
                    this.$root.$data.loading = false

                    this.init()
                })
                .catch(err => {
                    this.$root.$data.loading = false
                    console.log(err)
                    Vue.$toast.error(err)
                })
            } else {
                this.termination.contract = undefined
                this.termination.open = false
            }
        },
        closeReplacementPopup: function(submit) {
            if (submit) {
                this.$v.replacement.$touch()

                if (this.$v.$anyError) {
                    return
                }

                this.$root.$data.loading = true

                let replacementurl = Vue.middleware()
                replacementurl += '/contracts/' + this.replacement.contract.IDBerechtigung
                replacementurl += '/replace'

                const replacementbody = {
                    customerid: this.cardholder.IDFKI,
                    message: this.replacement.message,
                    reason: this.replacement.reason.original
                }

                axios.delete(replacementurl, replacementbody)
                .then(response => {

                    if (response.data.status.ErrorCode !== 0 ) {
                        throw response.data.status.ErrorMsg
                    } else {
                        Vue.$toast.info('Ihr Ersatzkartenantrag wurde erfolgreich übermittelt')
                    }

                    this.replacement.contract = undefined
                    this.replacement.open = false
                    this.$root.$data.loading = false

                    this.init()
                })
                .catch(err => {
                    this.$root.$data.loading = false
                    console.log(err)
                    Vue.$toast.error(err)
                })
            } else {
                this.replacement.contract = undefined
                this.replacement.open = false
            }
        }
    },
    components: {
        Tile,
        Popup,
        DropdownInput,
        DateInput,
        TextareaInput
    }
}
</script>

<style scoped>
.item.contract >>> .content {
    display: flex;
}

.contract-info {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.contract-info > * {
    min-width: 50%;
    flex: 1;
    padding: 0 20px;
}

.contract-info > *:nth-of-type(odd) {
    text-align: left;
    font-weight: bold;
}

.contract-info> *:nth-of-type(even) {
    text-align: right;
}

.content-container {
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    gap: 20px;
}

.content-container > div {
    flex: 1;
}

.heading {
    height: 100px;
    line-height: 50px;
    font-size: 2.5em;
    font-weight: bold;
    background: unset;
    box-shadow: unset;
    width: 350px;
    margin: 0 auto;
    margin-top: 20px;
    box-shadow: 0px 2px 5px rgb(100 100 150 / 30%);
    border-radius: 8px;
}

.heading > label {
    display: block;
    /* color: #fff; */
    /* text-shadow: 0px 2px 5px rgb(50 50 100 / 75%); */
    background-color: rgba(255, 255, 255, 0.5);
    color: #123456;
    border-radius: 8px;
}

.item {
    margin-top: 20px !important;
}

.item.personal-data > label {
    display: block;
    text-align: left;
    width: 100%;
    font-size: 1.2em;
    font-weight: bold;
}

.new-contract > .heading {
    display: block;
    margin-top: 10px;
    font-size: 1.5em;
    font-weight: bold;
}

.new-contract >>> .info {
    padding: 20px;
    display: block;
    font-size: 2em;
    font-weight: bold;
    margin-top: 30px;
}

.personal-data-details {
    display: flex;
}

.personal-data-details > div {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.personal-data-details > div > label {
    width: 80%;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}

.personal-data-details > div > label:nth-child(odd) {
    margin-top: 10px;
    font-weight: bold;
}
</style>