<template>
    <div v-if="isloading" class="input-line-loading-placeholder shimmer big"></div>
    <div v-else class="input-wrapper" :class="[{ 'error' : error }, { 'with-label' : label }, {'display':this.display}]">
        <select class="input" v-if="!display" :id="inputid" :required="required" :placeholder="placeholder" :disabled="disabled" v-model="inputvalue" :style="backgroundcolor ? 'background-color: ' + backgroundcolor + ' !important;' : ''">
            <option v-if="defaultoption" :value="defaultitem.displayid" >{{ defaultitem.displayname }}</option>
            <option v-for="option in optionlist" :key="option.displayid" :value="option.displayid">{{ option.displayname }}</option>
        </select>

        <label v-else class="display">{{ selecteddisplay }}</label>
        
        <label class="input-label" :for="inputid" :class="{ 'with-content' : inputvalue !== null && inputvalue !== this.defaultitem.displayid }" :style="backgroundcolor ? 'background-color: ' + backgroundcolor + ' !important;' : ''" v-if="label">{{ this.label }} {{ required ? '*' : '' }}</label>

        <div class="error-container">
            <label class="error-label" :class="{ 'show' : error }">{{ this.errortext }}</label>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DropdownInput',
    props: {
        value: {
            type: [Number, String],
            required: true
        },
        defaultoptionid: {
            type: Number,
            default: -1
        },
        placeholder: {
            type: String,
            default: ' ' // is used to trigger the floating label so it can not be empty
        },
        label: String,
        required: Boolean,
        disabled: Boolean,
        display: Boolean,
        isloading: Boolean,
        defaultoption: Boolean,
        optionlist: Array, // [] of "{displayid: 0, displayname: 'name', original: {object} }"
        error: Boolean,
        errortext: String,
        backgroundcolor: String
    },
    computed: {
        defaultitem() {
            return { displayid: this.defaultoptionid, displayname: '--- ' + 'Bitte auswählen' + ' ---'}
        },
        inputid: function() {
            return `input-${this._rng(5)}`
        },
        inputvalue: {
            get() {
                return parseInt(this.value)
            },
            set(val) {
                this.$emit('input', this.getCurrentItem(val))
            }
        },
        selecteddisplay() {
            if (this.inputvalue === -1) return this.defaultitem.displayname
            else return this.optionlist.filter(item => { item.displayid === this.inputvalue }).displayname
        },
    },
    methods: {
        getCurrentItem(displayid) {
            var selected
            if (displayid === -1) {
                selected = this.defaultitem
            } else {
                selected = this.optionlist.find(entry => entry.displayid === displayid)
            }
            selected.displayid = parseInt(selected.displayid)
            return selected
        }
    }
}
</script>

<style scoped>
.input-wrapper {
    position: relative;
}

.input-wrapper.with-label {
    padding-top: 15px;
}

.input-wrapper > .input-label {
    position: absolute;
    font-size: 1em;
    left: 10px;
    background: #fff;
    top: 7px;
    padding: 0px 5px;
    transition: .1s all;
    height: 10px;
    line-height: 10px;
}

.input-wrapper > .display {
    display: block;
    white-space: pre-wrap;
    text-align: left;
    padding-left: 10px;
    height: 30px;
    line-height: 30px;
    border-radius: var(--input-border-radius);
    border: 1px solid #ccc;
}

.input-wrapper > .input-label.with-content {
    top: 0px;
    left: 0px;
}

select {
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    font-size: 1.2em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-color: #fff;
    border-radius: var(--input-border-radius);
    padding-left: 10px;
    border: 1px solid #064A6C;
}

select::placeholder {
    font-size: 1em;
}

.textinput-container > label {
    color: #fff;
}

.input-label {
    font-size: 1.1em;
    color: #2c2e2f;
}

.error-container {
    min-height: 15px;
    margin-top: 3px;
    padding-left: 10px;
    color: red;
    text-align: left;
}

.error-container > .error-label {
    visibility: hidden;
}

.error-container > .error-label.show {
    visibility: visible;
}

.input-wrapper.error >>> * {
    color: red;
    border-color: red;
}

.input-wrapper.error > input {
    border: 1px solid red;
}
</style>