<template>
    <div class="item new-contract">
        <div class="background">
            <div class="image">
                <img v-if="imgtype === 0" src="@/assets/img/personal-details-banner.jpg" :title="imgattr" class="personal-details"/>
                <img v-if="imgtype === 1" src="@/assets/img/new-contract-banner.jpg" :title="imgattr" class="new-contract"/>
                <img v-if="imgtype === 2" src="@/assets/img/contract-details-banner.jpg" :title="imgattr" class="contract-details"/>
                <label v-if="title">{{ title }}</label>
            </div>
        </div>

        <div class="content">
            <slot></slot>
        </div>

        <div class="footer">
            <slot name="buttons"></slot>
            <div class="icon" v-if="!withouticon" @click="iconclick">
                <slot name="icon">
                    <v-icon name="plus" class="icon"/>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
const EMIT_ICON_CLICK = 'iconclick'

export default {
    name: 'tile',
    emits: [EMIT_ICON_CLICK],
    data() {
        return {
            imgattr: 'Archive vector created by vectorjuice - www.freepik.com - https://www.freepik.com/author/vectorjuice'
        }
    },
    props: {
        withouticon: {
            type: Boolean,
            default: false
        },
        imgtype: {
            type: Number,
            default: 0
        },
        title: String
    },
    methods: {
        iconclick: function() {
            this.$emit(EMIT_ICON_CLICK)
        }
    }
}
</script>

<style scoped>
.item {
    position: relative;
    background: #fff;
    border-radius: 10px;
    width: 350px;
    min-height: 300px;
    overflow: hidden;
    box-shadow: 0px 2px 5px rgb(100 100 150 / 30%);
    margin: 0 auto;
}

.item > .content {
    padding: 10px;
    min-height: 150px;
    width: 100%;
}

.item > .footer {
    width: 100%;
    height: 65px;
    text-align: center;
    padding-right: 70px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.footer > .icon {
    position: absolute;
    background: #999;
    border-top-left-radius: 8px;
    fill: #fff;
    width: 50px;
    height: 50px;
    padding: 10px;
    bottom: -12px;
    right: -12px;
    transition: all 0.3s ease-out;
}

.footer > .icon > svg {
    height: 20px;
    width: 20px;
    line-height: 20px;
    margin-right: 10px;
    transition: all 0.3s ease-out;
}

/* extra trigger */
.footer > .icon:after {
    position: absolute;
    content: ' ';
    width: 100px;
    height: 100px;
    top: -50px;
    left: -50px;
    transition: all 0.3s ease-out;
}

.footer > .icon:hover:after {
    width: 65px;
    height: 65px;
    top: 0;
    left: 0;
}

.footer > .icon:hover {
    bottom: 0px;
    width: 65px;
    height: 65px;
    right: 0px;
    cursor: pointer;
    background: #162c99;
}
.footer > .icon:hover > svg {
    margin-right: 0;
    margin-top: 15px;
}

.image {
    width: 350px;
    height: 105px;
    overflow: hidden;
    /* box-shadow: 0px 2px 5px rgb(100 100 150 / 30%); */
}

.image > img {
    position: relative;
    width: 100%;
    height: auto;
}

.image > label {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 2em;
    font-weight: bold;

}

.image > label {
    color: #012345;
    background-color: #fff;
    border-radius: 8px;
    padding: 2px 10px;
}

/* .image > label {
    color: #fff;
    text-shadow: 0px 2px 5px rgb(50 50 100 / 75%);
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    padding: 2px 10px;
} */
</style>