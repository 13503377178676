import Vue from 'vue'
import App from './App.vue'
import env from '@/plugins/util'
import i18n from '@/plugins/i18n'
import Icon from 'vue-awesome'
import mixin from '@/plugins/mixin'
import toast from './plugins/toast'
import router from '@/plugins/router'
import vuelidate from '@/plugins/vuelidate'
import towssession from '@/plugins/tows-session'
import clickoutside from '@/plugins/clickoutside'

Vue.config.productionTip = false

Vue.component('v-icon', Icon)

let SESSION = {
    id: undefined,
    client: undefined,
    clientid: undefined,
    corporation: undefined,
    corporationid: undefined,
    created: undefined,
    refreshed: undefined,
    refreshing: false,
    available: false
}

let SYSTEMCONFIGURATION = {
    configuration: undefined,
    linklist: undefined,
    clientlogo: undefined,
    fields: undefined
}

let USERPROFILE = {
    userid: undefined,
    token: undefined,
    email: undefined,
    user: undefined,
    validitywarning: undefined,
    roles: undefined,
    mainrole: undefined,
    client: undefined,
    profilesettings: undefined
}

const emptyuserprofile = {}

Object.assign(emptyuserprofile, USERPROFILE)

Vue.use(towssession, { session: SESSION, config: SYSTEMCONFIGURATION })

new Vue({
    data: {
        session: SESSION,
        systemconfiguration: SYSTEMCONFIGURATION,
        loading: false,
        auth: USERPROFILE,
        customer: undefined,
        logout: function() {
            this.auth = emptyuserprofile
            this.customer = undefined
            router.push('/')
        }
    },
    router,
    i18n,
    vuelidate,
    mixin,
    env,
    toast,
    clickoutside,
    render: h => h(App)
})
.$mount('#app')
