<template>
    <div class="page-container">
        <div class="content-container">
            <div class="content">
                <div class="image">
                    <img src="@/assets/img/personal-details-banner.jpg" :title="imgattr"/>
                    <label>Persönliche Daten ändern</label>
                </div>

                <div class="info-head">
   <!--                  <div class="picture">
                        <div>Bild</div>
                    </div> -->
                    <div class="customer-nr">
                        <div>Kundennummer</div>
                        <div>0123456</div>
                    </div>
                </div>

                <div class="button-box">
                    <button class="passive" @click="goBack">Zurück</button>
                    <button class="active" @click="save">Speichern</button>
                </div>
            </div>

            <div class="input-wrapper">
                <Accordion :error="personaldataerror" @click="accordionClicked(PERSONAL_PANEL)" class="input-container round-container" :ref="PERSONAL_PANEL" :open="openpanel === PERSONAL_PANEL" title="Personendaten">
                    <div class="panel-container">
                        <DropdownInput class="input" :error="$v.uicustomer.salutation.$error" :required="$v.uicustomer.salutation.$params.required !== undefined" label="Anrede" :defaultoption="true" @input="event => uicustomer.salutation = event" :value="uicustomer.salutation.displayid" :optionlist="salutations"/>
                        <DropdownInput class="input" :error="$v.uicustomer.title.$error" label="Titel" :required="$v.uicustomer.title.$params.required !== undefined" :defaultoption="true" @input="event => uicustomer.title = event" :value="uicustomer.title.displayid"  :optionlist="titles"/>
                        
                        <TextInput class="input" :error="$v.uicustomer.firstname.$error" :required="$v.uicustomer.firstname.$params.required !== undefined" label="Vorname" v-model="uicustomer.firstname"/>
                        <TextInput class="input" :error="$v.uicustomer.lastname.$error" :required="$v.uicustomer.lastname.$params.required !== undefined" label="Nachname" v-model="uicustomer.lastname"/>
                        
                        <DateInput class="input" :error="$v.uicustomer.birthdate.$error" :required="$v.uicustomer.birthdate.$params.required !== undefined" label="Geburtstag" v-model="uicustomer.birthdate"/>
                        <TextInput class="input" :error="$v.uicustomer.co.$error" :required="$v.uicustomer.co.$params.required !== undefined" label="Wohnhaft bei" v-model="uicustomer.co"/>

                        <TextInput class="input" :error="$v.uicustomer.email.$error" :required="$v.uicustomer.email.$params.required !== undefined" label="Email" v-model="uicustomer.email"/>
                        <TextInput class="input" :error="$v.uicustomer.confirmemail.$error" :required="$v.uicustomer.confirmemail.$params.required !== undefined" label="Email bestätigen" v-model="uicustomer.confirmemail"/>

                        <TextInput class="input" :error="$v.uicustomer.phoneprivate.$error" :required="$v.uicustomer.phoneprivate.$params.required !== undefined" label="Telefon privat" v-model="uicustomer.phoneprivate"/>
                        <TextInput class="input" :error="$v.uicustomer.phonebusiness.$error" :required="$v.uicustomer.phonebusiness.$params.required !== undefined" label="Telefon geschäftlich" v-model="uicustomer.phonebusiness"/>

                        <TextInput class="input" :error="$v.uicustomer.phonemobile.$error" :required="$v.uicustomer.phonemobile.$params.required !== undefined" label="Telefon mobil" v-model="uicustomer.phonemobile"/>
                        <div class="input"></div>

                        <div class="next-container input full">
                            <button class="passive" @click="personalDataNext">
                                <label>Weiter</label>
                                <v-icon name="arrow-down"/>
                            </button>
                        </div>
                    </div>
                </Accordion>

                <Accordion :error="adressdataerror" @click="accordionClicked(ADDRESS_PANEL)" class="input-container round-container" :ref="ADDRESS_PANEL" :open="openpanel === ADDRESS_PANEL" title="Adressdaten">
                    <div class="panel-container">
                        <div class="input full header">
                            <label>Adresse</label>
                        </div>

                        <TextInput class="input" :error="$v.uicustomer.street.$error" :required="$v.uicustomer.street.$params.required !== undefined" label="Straße" v-model="uicustomer.street"/>
                        <TextInput class="input" :error="$v.uicustomer.housenr.$error" :required="$v.uicustomer.housenr.$params.required !== undefined" label="Hausnummer" v-model="uicustomer.housenr"/>
                        
                        <TextInput class="input" :error="$v.uicustomer.zip.$error" :required="$v.uicustomer.zip.$params.required !== undefined" label="PLZ" v-model="uicustomer.zip"/>
                        <TextInput class="input" :error="$v.uicustomer.city.$error" :required="$v.uicustomer.city.$params.required !== undefined" label="Ort" v-model="uicustomer.city"/>
                        
                        <DropdownInput class="input" :error="$v.uicustomer.country.$error" :required="$v.uicustomer.country.$params.required !== undefined" label="Land" :defaultoption="true" @input="event => uicustomer.country = event" :value="uicustomer.country.displayid" :optionlist="countries"/>
                        <div class="input"></div>

                        <TextInput class="input" :error="$v.uicustomer.po.$error" :required="$v.uicustomer.po.$params.required !== undefined" label="Postfach" v-model="uicustomer.po"/>
                        <TextInput class="input" :error="$v.uicustomer.pozipcity.$error" :required="$v.uicustomer.pozipcity.$params.required !== undefined" label="Postfach-PLZ / Ort" v-model="uicustomer.pozipcity"/>

                        <div class="next-container input full">
                            <button class="passive" @click="adressDataNext">
                                <label>Weiter</label>
                                <v-icon name="arrow-down"/>
                            </button>
                        </div>
                    </div>
                </Accordion>

                <Accordion :error="bankingdataerror" @click="accordionClicked(BANNKING_PANEL)" class="input-container round-container" :ref="BANNKING_PANEL" :open="openpanel === BANNKING_PANEL" title="Bankverbindung">
                    <div class="panel-container">
                        <div class="input full header">
                            <label>Kontodaten</label>
                        </div>

                        <TextInput class="input" v-if="!uicustomer.differentbankaccountowner" :display="true" :value="uicustomer.firstname + ' ' + uicustomer.lastname" label="Kontoinhaber"/>
                        <span v-if="!uicustomer.differentbankaccountowner" class="input placeholder" />

                        <TextInput class="input" :error="$v.uicustomer.iban.$error" :required="$v.uicustomer.iban.$params.required !== undefined" label="IBAN" v-model="uicustomer.iban"/>
                        <TextInput class="input" :error="$v.uicustomer.bic.$error" :required="$v.uicustomer.bic.$params.required !== undefined" label="BIC" v-model="uicustomer.bic"/>

                        <div class="input full header">
                            <label>Abweichender Kontoinhaber</label>
                        </div>

                        <CheckboxInput class="input" text="Abweichender Kontoinhaber" v-model="uicustomer.differentbankaccountowner"/>
                        <span class="input" />

                        <div class="next-container input full">
                            <button class="passive" @click="bankingDataNext">
                                <label>Weiter</label>
                                <v-icon name="arrow-down"/>
                            </button>
                        </div>
                    </div>
                </Accordion>

                <Accordion :error="bankaccountownerdataerror" v-if="uicustomer.differentbankaccountowner" @click="accordionClicked(BANNKING_OWNER_PANEL)" class="input-container round-container" :ref="BANNKING_OWNER_PANEL" :open="openpanel === BANNKING_OWNER_PANEL" title="Kontoinhaber">
                    <div class="panel-container">
                        <div class="input full header">
                            <label>Persönliche Daten</label>
                        </div>

                        <DropdownInput class="input" :error="$v.uibankaccountowner.salutation.$error" :required="$v.uibankaccountowner.salutation.$params.required !== undefined" label="Anrede" :defaultoption="true" @input="event => uibankaccountowner.salutation = event" :value="uibankaccountowner.salutation.displayid" :optionlist="salutations"/>
                        <DropdownInput class="input" :error="$v.uibankaccountowner.title.$error" :required="$v.uibankaccountowner.title.$params.required !== undefined" label="Titel" :defaultoption="true" @input="event => uibankaccountowner.title = event" :value="uibankaccountowner.title.displayid" :optionlist="titles"/>
                        
                        <TextInput class="input" :error="$v.uibankaccountowner.firstname.$error" :required="$v.uibankaccountowner.firstname.$params.required !== undefined" label="Vorname" v-model="uibankaccountowner.firstname"/>
                        <TextInput class="input" :error="$v.uibankaccountowner.lastname.$error" :required="$v.uibankaccountowner.lastname.$params.required !== undefined" label="Nachname" v-model="uibankaccountowner.lastname"/>
                        
                        <DateInput class="input" :error="$v.uibankaccountowner.birthdate.$error" :required="$v.uibankaccountowner.birthdate.$params.required !== undefined" label="Geburtstag" v-model="uibankaccountowner.birthdate"/>
                        <TextInput class="input" :error="$v.uibankaccountowner.co.$error" :required="$v.uibankaccountowner.co.$params.required !== undefined" label="Wohnhaft bei" v-model="uibankaccountowner.co"/>

                        <TextInput class="input" :error="$v.uibankaccountowner.email.$error" :required="$v.uibankaccountowner.email.$params.required !== undefined" label="Email" v-model="uibankaccountowner.email"/>
                        <TextInput class="input" :error="$v.uibankaccountowner.confirmemail.$error" :required="$v.uibankaccountowner.confirmemail.$params.required !== undefined" label="Email bestätigen" v-model="uibankaccountowner.confirmemail"/>

                        <TextInput class="input" :error="$v.uibankaccountowner.phoneprivate.$error" :required="$v.uibankaccountowner.phoneprivate.$params.required !== undefined" label="Telefon privat" v-model="uibankaccountowner.phoneprivate"/>
                        <TextInput class="input" :error="$v.uibankaccountowner.phonebusiness.$error" :required="$v.uibankaccountowner.phonebusiness.$params.required !== undefined" label="Telefon geschäftlich" v-model="uibankaccountowner.phonebusiness"/>

                        <TextInput class="input" :error="$v.uibankaccountowner.phonemobile.$error" :required="$v.uibankaccountowner.phonemobile.$params.required !== undefined" label="Telefon mobil" v-model="uibankaccountowner.phonemobile"/>

                        <div class="input full header">
                            <label>Adresse</label>
                        </div>

                        <TextInput class="input" :error="$v.uibankaccountowner.street.$error" :required="$v.uibankaccountowner.street.$params.required !== undefined" label="Straße" v-model="uibankaccountowner.street"/>
                        <TextInput class="input" :error="$v.uibankaccountowner.housenr.$error" :required="$v.uibankaccountowner.housenr.$params.required !== undefined" label="Hausnummer" v-model="uibankaccountowner.housenr"/>
                        
                        <TextInput class="input" :error="$v.uibankaccountowner.zip.$error" :required="$v.uibankaccountowner.zip.$params.required !== undefined" label="PLZ" v-model="uibankaccountowner.zip"/>
                        <TextInput class="input" :error="$v.uibankaccountowner.city.$error" :required="$v.uibankaccountowner.city.$params.required !== undefined" label="Ort" v-model="uibankaccountowner.city"/>
                        
                        <DropdownInput class="input" :error="$v.uibankaccountowner.country.$error" :required="$v.uibankaccountowner.country.$params.required !== undefined" label="Land" :defaultoption="true" @input="event => uibankaccountowner.country = event" :value="uibankaccountowner.country.displayid" :optionlist="countries"/>
                        <div class="input"></div>

                        <TextInput class="input" :error="$v.uibankaccountowner.po.$error" :required="$v.uibankaccountowner.po.$params.required !== undefined" label="Postfach" v-model="uibankaccountowner.po"/>
                        <TextInput class="input" :error="$v.uibankaccountowner.pozipcity.$error" :required="$v.uibankaccountowner.pozipcity.$params.required !== undefined" label="Postfach-PLZ / Ort" v-model="uibankaccountowner.pozipcity"/>

                        <div class="next-container input full">
                            <button class="passive" @click="differentbankaccountownerDataNext">
                                <label>Weiter</label>
                                <v-icon name="arrow-down"/>
                            </button>
                        </div>
                    </div>
                </Accordion>

                <Accordion @click="accordionClicked(REFERENCE_PANEL)" class="input-container round-container" :ref="REFERENCE_PANEL" :open="openpanel === REFERENCE_PANEL" title="Kontakt">
                    <div class="panel-container">
                        <div class="input full header">
                            <label>Einwilligungen</label>
                        </div>

                        <CheckboxInput class="input" text="Übermittlung per Telefon" v-model="uicustomer.allowcontactphone"/>
                        <CheckboxInput class="input" text="Übermittlung per E-Mail" v-model="uicustomer.allowcontactmail"/>

                        <div class="next-container input full">
                            <button class="active" @click="referenceDataNext">
                                <label>Speichern</label>
                            </button>
                        </div>
                    </div>
                </Accordion>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'

import Accordion from '../components/systems/elements/Accordion.vue'
import TextInput from '../components/systems/elements/TextInput.vue'
import DateInput from '../components/systems/elements/DateInput.vue'
import CheckboxInput from '../components/systems/elements/CheckboxInput.vue'
import DropdownInput from '../components/systems/elements/DropdownInput.vue'

import {
    required,
    email,
    sameAs,
} from 'vuelidate/lib/validators'

export default {
    name: 'edit-user-data-page',
    data() {
        return {
            imgattr: 'Archive vector created by vectorjuice - www.freepik.com - https://www.freepik.com/author/vectorjuice',
            openpanel: undefined,
            activepanels: [],
            salutations: [],
            titles: [],
            countries: [],
            uicustomer: {
                salutation: { displayid: -1, displayname: '' },
                title: { displayid: -1, displayname: '' },
                firstname: '',
                lastname: '',
                birthdate: '',
                co: '',
                email: '',
                confirmemail: '',
                phoneprivate: '',
                phonebusiness: '',
                phonemobile: '',
                street: '',
                housenr: '',
                zip: '',
                city: '',
                country: { displayid: -1, displayname: '' },
                po: '',
                pozipcity: '',
                allowcontactphone: false,
                allowcontactmail: false,
                bic: '',
                iban: '',
                differentbankaccountowner: false
            },
            uibankaccountowner: {
                salutation: { displayid: -1, displayname: '' },
                title: { displayid: -1, displayname: '' },
                firstname: '',
                lastname: '',
                birthdate: '',
                co: '',
                email: '',
                confirmemail: '',
                phoneprivate: '',
                phonebusiness: '',
                phonemobile: '',
                street: '',
                housenr: '',
                zip: '',
                city: '',
                country: { displayid: -1, displayname: '' },
                po: '',
                pozipcity: '',
            }
        }
    },
    validations() {
        let validations = {
            uicustomer: {
                email: {
                    required,
                    email
                },
                confirmemail: {
                    required,
                    email,
                    sameAsEmail: sameAs('email')
                },
                salutation: {
                    required,
                    notdefault: value => value.displayid !== -1
                },
                title: {},
                firstname: {
                    required
                },
                lastname: {
                    required
                },
                birthdate: {
                    required
                },
                co: {},
                phoneprivate: {},
                phonebusiness: {},
                phonemobile: {},
                street: {
                    required
                },
                housenr: {
                    required
                },
                zip: {
                    required
                },
                city: {
                    required
                },
                country: {},
                po: {},
                pozipcity: {},
                differentcardowner: {},
                iban: {
                    // DK has shortest IBAN with 18 chars
                    format: value => !!value.replaceAll(' ', '').match(/^[a-zA-Z]{2}[0-9]{16,}$/),
                    required
                },
                bic: {},
                differentbankaccountowner: {},
                allowcontactphone: {},
                allowcontactmail: {}
            }
        }

        if (this.uicustomer.differentbankaccountowner) {
            validations.uibankaccountowner = {
                email: {
                    required,
                    email
                },
                confirmemail: {
                    required,
                    email,
                    sameAsEmail: sameAs('email')
                },
                salutation: {
                    required,
                    notdefault: value => value.displayid !== -1
                },
                title: {},
                firstname: {
                    required
                },
                lastname: {
                    required
                },
                birthdate: {
                    required
                },
                co: {},
                phoneprivate: {},
                phonebusiness: {},
                phonemobile: {},
                street: {
                    required
                },
                housenr: {
                    required
                },
                zip: {
                    required
                },
                city: {
                    required
                },
                country: {},
                po: {},
                pozipcity: {},
                differentcardowner: {},
            }
        }

        return validations
    },
    created() {
        // "const" declarations
        this.PERSONAL_PANEL             = 0
        this.ADDRESS_PANEL              = 1
        this.BANNKING_PANEL             = 2
        this.BANNKING_OWNER_PANEL       = 3
        this.REFERENCE_PANEL            = 4

        this.LAST_PANEL = this.REFERENCE_PANEL
        this.openpanel = this.PERSONAL_PANEL

        this.init()
    },
    computed: {
        customer() {
            return this.$root.$data.customer
        },
        personaldataerror() {
            return this.$v.uicustomer.salutation.$error
                || this.$v.uicustomer.title.$error
                || this.$v.uicustomer.firstname.$error
                || this.$v.uicustomer.lastname.$error
                || this.$v.uicustomer.birthdate.$error
                || this.$v.uicustomer.co.$error
                || this.$v.uicustomer.email.$error
                || this.$v.uicustomer.confirmemail.$error
                || this.$v.uicustomer.phoneprivate.$error
                || this.$v.uicustomer.phonebusiness.$error
                || this.$v.uicustomer.phonemobile.$error
        },
        adressdataerror() {
            return this.$v.uicustomer.street.$error
                || this.$v.uicustomer.housenr.$error
                || this.$v.uicustomer.zip.$error
                || this.$v.uicustomer.city.$error
                || this.$v.uicustomer.country.$error
                || this.$v.uicustomer.po.$error
                || this.$v.uicustomer.pozipcity.$error
        },
        bankingdataerror() {
            return this.$v.uicustomer.bic.$error
                || this.$v.uicustomer.iban.$error
        },
        bankaccountownerdataerror() {
            return this.$v.uibankaccountowner.salutation.$error
                || this.$v.uibankaccountowner.title.$error
                || this.$v.uibankaccountowner.firstname.$error
                || this.$v.uibankaccountowner.lastname.$error
                || this.$v.uibankaccountowner.birthdate.$error
                || this.$v.uibankaccountowner.co.$error
                || this.$v.uibankaccountowner.email.$error
                || this.$v.uibankaccountowner.confirmemail.$error
                || this.$v.uibankaccountowner.phoneprivate.$error
                || this.$v.uibankaccountowner.phonebusiness.$error
                || this.$v.uibankaccountowner.phonemobile.$error
                || this.$v.uibankaccountowner.street.$error
                || this.$v.uibankaccountowner.housenr.$error
                || this.$v.uibankaccountowner.zip.$error
                || this.$v.uibankaccountowner.city.$error
                || this.$v.uibankaccountowner.country.$error
                || this.$v.uibankaccountowner.po.$error
                || this.$v.uibankaccountowner.pozipcity.$error
        },
    },
    methods: {
        init: function() {
            this.initSelectionData()
            .then(() => {
                // "refresh" customer data
                return this.getCustomer()
            })
            .then(() => {
                this.prepareCustomer()
            })
            .catch(err => {
                Vue.$toast.error(err)
                console.log(err)
            })
        },
        goBack: function() {
            this.$router.push('/')
        },
        save: function() {
            this.$v.$touch()

            if (this.$v.$anyError) {
                this.openPanelWithError()
                return
            } else {
                this.$root.$data.loading = true

                let saveurl = Vue.middleware()
                saveurl += '/customer/' + this.customer.FKI.IDFKI

                const savebody = this.createCustomerToSave()

                axios.post(saveurl, savebody)
                .then(response => {
                    this.$root.$data.loading = false
                })
                .catch(err => {
                    Vue.$toast.error(err)
                    console.log(err)
                    this.$root.$data.loading = false
                })
                // dont go back
            }
        },
        openPanelWithError() {
            this.closeAllAccordions()

            if (this.personaldataerror) {
                this.nextPanel(-1)
            } else if (this.adressdataerror) {
                this.nextPanel(this.PERSONAL_PANEL)
            } else if (this.bankingdataerror) {
                this.nextPanel(this.ADDRESS_PANEL)
            } else if (this.bankaccountownerdataerror) {
                this.nextPanel(this.BANNKING_PANEL)
            }
        },
        personalDataNext() {
            this.nextPanel(this.PERSONAL_PANEL)
        },
        adressDataNext() {
            this.nextPanel(this.ADDRESS_PANEL)
        },
        bankingDataNext() {
            this.nextPanel(this.BANNKING_PANEL)
        },
        differentbankaccountownerDataNext() {
            this.nextPanel(this.BANNKING_OWNER_PANEL)
        },
        referenceDataNext() {
            this.nextPanel(this.REFERENCE_PANEL)
            this.save()
        },
        nextPanel(current) {
            // if there are hidden panels, we need to skip them
            const next = this.getNextPanelRef(current, this.LAST_PANEL)

            this.openpanel = next

            if (!this.activepanels.includes(next)) this.activepanels.push(next)

            // close current and open next
            if (this.$refs[current]) this.$refs[current].toggle()
            if (this.$refs[next]) this.$refs[next].toggle()
        },
        getNextPanelRef(current, lastpanel) {
            if (this.$refs[current + 1] !== undefined || current === lastpanel) {
                return current + 1
            } else {
                return this.getNextPanelRef(current + 1)
            }
        },
        accordionClicked(panel) {
            // close all accordions but the clicked one
            Object.keys(this.$refs).forEach(key => {
                const entry = this.$refs[key]
                if (key === panel || (entry && entry.open)) {
                    entry.toggle()
                }
            })

            this.openpanel = panel
        },
        closeAllAccordions() {
            for(let i=0; i <= this.LAST_PANEL; ++i) {
                this.accordionClicked(i)
            }
        },
        initSelectionData() {
            this.$root.$data.loading = true

            var url = Vue.middleware()
            url += '/basedata/salutations'

            return axios.get(url)
            .then(result => {
                this.salutations = result.data.data.salutationlist.map(entry => {
                    return {
                        displayid: entry.IDAnrede,
                        displayname: entry.AnredeText
                    }
                })

                var titlesurl = Vue.middleware()
                titlesurl += '/basedata/titles'

                return axios.get(titlesurl)
            })
            .then(result => {
                this.titles = result.data.data.titlelist.map(entry => {
                    return {
                        displayid: entry.IDTitel,
                        displayname: entry.TitelText
                    }
                })

                var countriesurl = Vue.middleware()
                countriesurl += '/basedata/countries'

                return axios.get(countriesurl)
            })
            .then(result => {
                this.countries = result.data.data
                this.uicustomer.country = this.countries[0]
                this.uibankaccountowner.country = this.countries[0]
            })
            .then(() => {
                this.$root.$data.loading = false
            })
            .catch(err => {
                this.$root.$data.loading = false
                console.log(err)
            })
        },
        getCustomer() {
            this.$root.$data.loading = true

            let url = Vue.middleware()
            url += '/customer/' + this.$root.$data.auth.token

            return axios.get(url)
            .then(response => {
                const status = response.data.status
                const data   = response.data.data

                if (status.ErrorCode !== 0) {
                    Vue.$toast.error(status.ErrorMsg)
                } else {
                    this.$root.$data.customer = data
                }

                this.$root.$data.loading = false
            })
            .catch(err => {
                console.log(err)
                this.$root.$data.loading = false
            })
        },
        prepareCustomer() {
            const personaldata = this.customer.FKI.Personendaten
            const accountownerdata = this.customer.KontoInhaber.Personendaten
            const bankdata = this.customer.KontoInhaber

            // personal data
            this.uicustomer.salutation = this.salutations.find(entry => entry.displayname === personaldata.Anrede.trim()) || { displayid: -1 }
            this.uicustomer.title = this.titles.find(entry => entry.displayname === personaldata.Titel.trim()) || { displayid: -1 }
            this.uicustomer.firstname = personaldata.Vorname
            this.uicustomer.lastname = personaldata.Nachname
            this.uicustomer.birthdate = personaldata.GebDatumDate
            this.uicustomer.co = personaldata.co
            this.uicustomer.email = personaldata.Email
            this.uicustomer.confirmemail = personaldata.Email
            this.uicustomer.phoneprivate = personaldata.Telefon
            this.uicustomer.phonebusiness = personaldata.TelefonGeschaeftlich
            this.uicustomer.phonemobile = personaldata.TelefonMobil

            // adress data
            this.uicustomer.street = personaldata.Strasse
            this.uicustomer.housenr = personaldata.HausNr
            this.uicustomer.zip = personaldata.PLZ
            this.uicustomer.city = personaldata.Ort
            this.uicustomer.country = this.countries.find(entry => entry.original === personaldata.Land.trim()) || this.countries[0]
            this.uicustomer.po = personaldata.POFA
            this.uicustomer.pozipcity = personaldata.POFAOrt

            // banking data
            this.uicustomer.iban = bankdata.IBAN
            this.uicustomer.bic = bankdata.BIC
            this.uicustomer.differentbankaccountowner = !this.customer.KVPIstIdentischMitKtoInh

            // bankaccount owner
            // only fill the accountowner if it's differing from the customer
            // else it is filled with the customer data
            if (this.uicustomer.differentbankaccountowner) {
                this.uibankaccountowner.salutation = this.salutations.find(entry => entry.displayname === accountownerdata.Anrede.trim()) || { displayid: -1 }
                this.uibankaccountowner.title = this.titles.find(entry => entry.displayname === accountownerdata.Titel.trim()) || { displayid: -1 }
                this.uibankaccountowner.firstname = accountownerdata.Vorname
                this.uibankaccountowner.lastname = accountownerdata.Nachname
                this.uibankaccountowner.birthdate = accountownerdata.GebDatumDate
                this.uibankaccountowner.co = accountownerdata.co
                this.uibankaccountowner.email = accountownerdata.Email
                this.uibankaccountowner.confirmemail = accountownerdata.Email
                this.uibankaccountowner.phoneprivate = accountownerdata.Telefon
                this.uibankaccountowner.phonebusiness = accountownerdata.TelefonGeschaeftlich
                this.uibankaccountowner.phonemobile = accountownerdata.TelefonMobil

                this.uibankaccountowner.street = accountownerdata.Strasse
                this.uibankaccountowner.housenr = accountownerdata.HausNr
                this.uibankaccountowner.zip = accountownerdata.PLZ
                this.uibankaccountowner.city = accountownerdata.Ort
                this.uibankaccountowner.country = this.countries.find(entry => entry.original === accountownerdata.Land.trim()) || this.countries[0]
                this.uibankaccountowner.po = accountownerdata.POFA
                this.uibankaccountowner.pozipcity = accountownerdata.POFAOrt
            }

            // contact
            this.uicustomer.allowcontactphone = this.customer.KVP.WerbeMassnahmen.TelefonErlaubt
            this.uicustomer.allowcontactmail = this.customer.KVP.WerbeMassnahmen.MailErlaubt
        },
        createCustomerToSave() {
            let savedata = {}
            // copy original object
            Object.assign(savedata, this.customer)

            // now reverse assign new values -> all old values (KVP id etc.) need to be reused!
            savedata.DatenGueltigAb = new Date()
            savedata.FKI.Personendaten.IDAnrede = this.uicustomer.salutation.displayid !== -1 ? this.uicustomer.salutation.displayid : 0
            savedata.FKI.Personendaten.IDTitel = this.uicustomer.title.displayid !== -1 ? this.uicustomer.salutation.displayid : 0
            savedata.FKI.Personendaten.Vorname = this.uicustomer.firstname
            savedata.FKI.Personendaten.Nachname = this.uicustomer.lastname
            savedata.FKI.Personendaten.co = this.uicustomer.co
            savedata.FKI.Personendaten.Strasse = this.uicustomer.street
            savedata.FKI.Personendaten.HausNr = this.uicustomer.housenr
            savedata.FKI.Personendaten.PLZ = this.uicustomer.zip
            savedata.FKI.Personendaten.Ort = this.uicustomer.city
            savedata.FKI.Personendaten.Land = this.uicustomer.country.displayid !== -1 ? this.uicustomer.country.original : savedata.FKI.Personendaten.Land
            savedata.FKI.Personendaten.POFA = this.uicustomer.po
            savedata.FKI.Personendaten.POFAOrt = this.uicustomer.pozipcity
            savedata.FKI.Personendaten.GebDatumDate = this.uicustomer.birthdate
            savedata.FKI.Personendaten.Telefon = this.uicustomer.phoneprivate
            savedata.FKI.Personendaten.TelefonGeschaeftlich = this.uicustomer.phonebusiness
            savedata.FKI.Personendaten.TelefonMobil = this.uicustomer.phonemobile
            savedata.FKI.Personendaten.Email = this.uicustomer.email
            
            savedata.KVP.WerbeMassnahmen.TelefonErlaubt = this.uicustomer.allowcontactphone
            savedata.KVP.WerbeMassnahmen.MailErlaubt = this.uicustomer.allowcontactmail

            // TODO: Fotospeichern zulassen
            savedata.KVPIstIdentischMitKtoInh = !this.uicustomer.differentbankaccountowner
            
            savedata.KontoInhaber.Personendaten.IDAnrede = this.uibankaccountowner.salutation.displayid !== -1 ? this.uibankaccountowner.salutation.displayid : 0
            savedata.KontoInhaber.Personendaten.IDTitel = this.uibankaccountowner.salutation.displayid !== -1 ? this.uibankaccountowner.salutation.displayid : 0
            savedata.KontoInhaber.Personendaten.Vorname = this.uibankaccountowner.firstname
            savedata.KontoInhaber.Personendaten.Nachname = this.uibankaccountowner.lastname
            savedata.KontoInhaber.Personendaten.co = this.uibankaccountowner.co
            savedata.KontoInhaber.Personendaten.Strasse = this.uibankaccountowner.street
            savedata.KontoInhaber.Personendaten.HausNr = this.uibankaccountowner.housenr
            savedata.KontoInhaber.Personendaten.PLZ = this.uibankaccountowner.zip
            savedata.KontoInhaber.Personendaten.Ort = this.uibankaccountowner.city
            savedata.KontoInhaber.Personendaten.Land = this.uibankaccountowner.country.displayid !== -1 ? this.uibankaccountowner.country.original : savedata.KontoInhaber.Personendaten.Land
            savedata.KontoInhaber.Personendaten.POFA = this.uibankaccountowner.po
            savedata.KontoInhaber.Personendaten.POFAOrt = this.uibankaccountowner.pozipcity
            savedata.KontoInhaber.Personendaten.GebDatumDate = this.uibankaccountowner.birthdate
            savedata.KontoInhaber.Personendaten.Telefon = this.uibankaccountowner.phoneprivate
            savedata.KontoInhaber.Personendaten.TelefonGeschaeftlich = this.uibankaccountowner.phonebusiness
            savedata.KontoInhaber.Personendaten.TelefonMobil = this.uibankaccountowner.phonemobile
            savedata.KontoInhaber.Personendaten.Email = this.uibankaccountowner.email
            
            savedata.KontoInhaber.BIC = this.uicustomer.bic
            savedata.KontoInhaber.IBAN = this.uicustomer.iban

            return savedata
        },
    },
    components: {
        Accordion,
        TextInput,
        DateInput,
        CheckboxInput,
        DropdownInput
    }
}
</script>

<style scoped>
.input-wrapper > .input-container {
    background: #fff;
}

.input-wrapper > .input-container {
    margin-top: 2em;
}

.input.header {
    text-align: left;
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
}

.panel-container > .additional-info {
    text-align: left;
}

.panel-container > .next-container {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.next-container > .next-button {
    margin-bottom: 15px;
    padding: 10px 15px;
    font-size: 1.3em;
    font-weight: bold;
    color: #fff;
    background-color: #064A6C;
    display: flex;
}

.complete-container:disabled,
.next-container > .next-button:disabled {
    background-color: #064A6C66;
}

.next-container > .next-button:not(:disabled):hover {
    background-color: #064A6Ccc;
}

.next-button > svg {
    margin-left: 5px;
    fill: #fff;
}

/* ---- */
.content {
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 2px 5px rgb(100 100 150 / 30%);
    padding-bottom: 20px;
    width: 372px;
    margin: 0 auto;
}

.content > .info-head {
    min-height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
}

.info-head > div {
    flex: 1;
}

.info-head > .customer-nr > div {
    height: 20px;
    margin: 10px;
}

.info-head > .customer-nr > div:nth-of-type(1) {
    font-weight: bold;
    font-size: 1.2em;
}

.info-head > .customer-nr > div:nth-of-type(2) {
    font-size: 1.1em;
}

.image {
    position: relative;
    width: 100%;
    height: 136px;
    overflow: hidden;
    /* box-shadow: 0px 2px 5px rgb(100 100 150 / 30%); */
}

.image > img {
    position: relative;
    width: 100%;
    height: auto;
}

.image > label {
    position: absolute;
    top: 2em;
    left: 2em;
    font-size: 2em;
    font-weight: bold;

}

.image > label {
    color: #fff;
    text-shadow: 0px 2px 5px rgb(50 50 100 / 75%);
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    padding: 2px 10px;
}

.button-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 20px;
}

@media(min-width: 576px) {
    .panel-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: row;
    }

    .panel-container.image {
        justify-content: space-around;
    }

    .panel-container > .input {
        width: 49%;
    }

    .panel-container > .input.full {
        width: 100%;
    }
}

@media(min-width: 768px) {
    .content {
        width: 100%;
    }
    
    .image {
        height: 186px;
    }
}

@media(min-width: 992px) {
    .image {
        height: 283px;
    }
}
</style>